const list = {
    header: [
        {
            id: 'name',
            label: 'Currency',
            align: 'left'
        },
        {
            id: 'rate',
            label: 'Rate',
            align: 'left'
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true
        },
    ],
    hide: {
        create: true,
    },
    permission: 'list',
    actions: ['show']
};

const show = {
    fields: [
        {
            type: 'text',
            name: 'name',
            label: 'Currency',
        },
        {
            type: 'number',
            name: 'rate',
            label: 'Rate',
        },
    ]
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'name'
        },
    ]
};

const data = { list, search, show };

export default data;