import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Text = ({ field, value, onChange, updateForm, show }) => {
    const { label, name } = field
    return (
        show ?
            <Box mb={3} >
                <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                    {field.label}
                </Typography>
                <Typography mb={3} >
                    {field?.rich ? <div dangerouslySetInnerHTML={{ __html: value }} /> : value}
                </Typography>
                <Divider />
            </Box> :
            <div className="form-control">
                <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={name}>{label}</label>
                {field?.rich ? <>
                    <ReactQuill theme="snow" name={name} id={name} value={value} onChange={(value) => {
                        updateForm(name, value);
                    }} />
                </>
                    : <input className="form-input" type="text" required={field?.required} name={name} id={name} value={value} placeholder={field?.placeholder} onChange={(e) => onChange(e)} />}
            </div>
    )
}

export default Text