import { createSlice } from '@reduxjs/toolkit';

// State on app load and refresh
const initialState = {
  list: [
    {
      label: 'Adminstration',
      icon: 'Shield',
      children: [
        {
          label: 'Admins',
          icon: 'SupervisorAccount',
          route: 'admins',
        },
        {
          label: 'Roles',
          icon: 'AddModerator',
          route: 'roles',
        },
        {
          label: 'Permissions',
          icon: 'AdminPanelSettings',
          route: 'permissions',
        },
      ],
    },
    {
      label: 'Users',
      icon: 'Groups',
      route: 'users'
    },
    {
      label: 'Draws',
      icon: 'Casino',
      route: 'draws'
    },
    {
      label: 'Transactions',
      icon: 'Receipt',
      route: 'transactions'
    },
    {
      label: 'Draw winners',
      icon: 'EmojiEvents',
      route: 'drawwinners'
    },
    {
      label: 'Scratch winners',
      icon: 'MilitaryTech',
      route: 'scratchwinners'
    },
    {
      label: 'Currency',
      icon: 'CurrencyExchange',
      route: 'currency'
    },
    {
      label: 'Contact Us',
      icon: 'ContactSupport',
      route: 'contact us',
    },
    {
      label: 'FAQs',
      icon: 'LiveHelp',
      route: 'faq',
      permission: 'form',
    },
    {
      label: 'Informative Screens',
      icon: 'Info',
      route: 'informative',
    },
    {
      label: 'Claim Requets',
      icon: 'Info',
      route: 'claim requests',
    },
    {
      label: 'Sections',
      icon: 'CropSquare',
      route: 'section',
    },
    {
      label: 'About Us',
      icon: 'Info',
      route: 'about us',
      permission: 'form',
    },
    {
      label: 'Privacy Policy',
      icon: 'ContactSupport',
      route: 'privacy policy',
      permission: 'form',
    },
    {
      label: 'Terms and Conditions',
      icon: 'ContactSupport',
      route: 'terms',
      permission: 'form',
    },
    {
      label: 'Support',
      icon: 'SupportAgent',
      route: 'support',
      permission: 'form',
    },
    {
      label: 'Settings',
      icon: 'Settings',
      children: [
        {
          label: 'SMTP',
          icon: 'Mail',
          route: 'smtp',
          permission: 'form',
        },

        {
          label: 'Find Us',
          icon: 'Directions',
          route: 'find us',
          permission: 'form',
        },
        {
          label: 'General Settings',
          icon: 'SupportAgent',
          route: 'links',
          permission: 'form',
        },
        {
          label: 'Social Media',
          icon: 'Share',
          route: 'social media',
          permission: 'form',
        },
      ],
    },
  ], // will hold the list of items
};

// Create the Users Slice
const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {},
});

// Export the slice reducer
export default sidebarSlice.reducer;
