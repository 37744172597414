import { useEffect, useState } from "react";
import { Link as RouterLink, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../../layouts/Breadcrumbs";
import DataTable from "../../layouts/DataTable";
import PermissionsManager from "../../layouts/PermissionsManager";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Sort as SortIcon,
  ArrowBack as ArrowBackIcon,
  DeleteSweep as DeleteSweepIcon,
} from "@mui/icons-material";
import { authorized } from "../../../utils/utils";
import Filter from "./Filter";
import Search from "./Search";
import useQueryParams from "../../../hooks/useQueryParams";

const List = ({ permission, name }) => {
  let [{ page, list, create, get, edit, remove, order, bulk_remove }] =
    useOutletContext();
  page = name ?? page;
  const dispatch = useDispatch();
  const { items, itemsLoading, count, page_number, limit } = useSelector(
    (state) => state[page.toString().toLowerCase()]
  );
  const user = useSelector((state) => state.auth.user);
  // const columns = useSelector(
  //   (state) => state[page.toString().toLowerCase()].properties.list.header
  // );
  // const actions = useSelector(
  //   (state) => state[page.toString().toLowerCase()].properties.list.actions
  // );
  // const hide = useSelector(
  //   (state) => state[page.toString().toLowerCase()].properties.list.hide
  // );
  const {
    filter,
    search,
    list: { header, actions, hide, back },
  } = useSelector((state) => state[page.toString().toLowerCase()].properties);

  const [orderData, setOrderData] = useState([]);
  const [bulk, setBulk] = useState([]);

  const [open, setOpen] = useState(false);

  const { params, getQueryParam, query } = useQueryParams();
  console.log(params);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    dispatch(bulk_remove({ data: { ids: bulk } }));
  };

  useEffect(() => {
    if (params)
      dispatch(
        list({
          page: 0,
          limit: 10,
          orderBy: null,
          orderDirection: null,
          filter: params,
          search: null,
        })
      );
    else dispatch(list());
  }, []);

  const bulkToggle = (value, group) => {
    if (group) {
      setBulk(value);
    } else {
      if (bulk.includes(value)) setBulk(bulk.filter((item) => item !== value));
      else setBulk([...bulk, value]);
    }
  };

  const onDelete = (id) => {
    dispatch(remove(id));
  };

  const generate_back_url = () => {
    let url = `/cms/${back?.page ?? page.toString().toLowerCase()}`;
    if (back) {
      let param_id = getQueryParam(back?.query_param);
      console.log(param_id);
      if (param_id) {
        url += `/${param_id}/${back?.route ?? ""}`;
      }
    }
    return url;
  };

  /* Filter Code */
  const [filterFormData, setFilterFormData] = useState(null);

  /* Search Code */
  const [searchFormData, setSearchFormData] = useState("");

  return (
    user?.Permissions && (
      <PermissionsManager
        action="list"
        name={page.toLowerCase()}
        permissions={user?.Permissions}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: 0 }}
        >
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove all these items?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Keep</Button>
            <Button onClick={handleConfirm} autoFocus color="error">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
        <Box height="100%">
          {hide?.breadcrumbs !== true && (
            <Breadcrumbs page={page.toString()} text={"List"} />
          )}
          {/* Header */}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            {/* Title */}
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              alignSelf={{ xs: "start", sm: "center" }}
            >
              {back && getQueryParam(back?.query_param) && (
                <>
                  {authorized(
                    user.Permissions,
                    back.page ?? page.toLowerCase(),
                    back.permission ?? "list"
                  ) && (
                    <IconButton
                      component={RouterLink}
                      to={generate_back_url()}
                      aria-label="arrow-back"
                      sx={{ border: "2px solid", borderRadius: "50%" }}
                      disableRipple
                      size="small"
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  )}
                </>
              )}
              {hide?.title !== true && (
                <Typography variant="h5" mr={1}>
                  {page}
                </Typography>
              )}
              {hide?.count !== true && <Chip label={count} size="small" />}
            </Stack>

            {/* Buttons */}
            <Stack
              direction="row"
              spacing={2}
              mt={{ xs: 2, sm: 0 }}
              alignSelf={{ xs: "end", sm: "center" }}
            >
              {hide?.create !== true &&
                authorized(user.Permissions, page.toLowerCase(), "form") && (
                  <Button
                    component={RouterLink}
                    to={`add${query}`}
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ borderRadius: 0, textTransform: "none" }}
                  >
                    Create new
                  </Button>
                )}

              {order &&
                authorized(user.Permissions, page.toLowerCase(), "order") && (
                  <Button
                    component={RouterLink}
                    to={`order${query}`}
                    variant="contained"
                    startIcon={<SortIcon />}
                    sx={{ borderRadius: 0, textTransform: "none" }}
                  >
                    Order
                  </Button>
                )}

              {actions?.includes("bulk remove") &&
                authorized(user.Permissions, page.toLowerCase(), "remove") && (
                  <Button
                    color="error"
                    onClick={() => handleClickOpen()}
                    variant="contained"
                    startIcon={<DeleteSweepIcon />}
                    sx={{ borderRadius: 0, textTransform: "none" }}
                  >
                    Bulk Remove
                  </Button>
                )}

              {filter && (
                <Filter
                  filter={filter}
                  dispatch={dispatch}
                  list={list}
                  params={params}
                  formData={filterFormData}
                  setFormData={setFilterFormData}
                  search={
                    searchFormData === ""
                      ? undefined
                      : {
                          keys: search?.fields
                            .map((item) => item.name)
                            .join(","),
                          value: searchFormData,
                        }
                  }
                  order={orderData}
                  setOrder={setOrderData}
                />
              )}
            </Stack>
          </Stack>
          {hide?.search !== true && (
            <Search
              filter={filterFormData}
              search={search}
              dispatch={dispatch}
              list={list}
              formData={searchFormData}
              setFormData={setSearchFormData}
              order={orderData}
              setOrder={setOrderData}
            />
          )}

          {/* Body */}
          <DataTable
            columns={header}
            rows={items}
            onDelete={onDelete}
            dispatch={dispatch}
            edit={edit}
            count={count}
            list={list}
            permissions={user?.Permissions}
            authorized={authorized}
            name={page.toLowerCase()}
            actions={actions}
            bulk={bulk}
            bulkToggle={bulkToggle}
            filter={filterFormData}
            search={
              searchFormData === ""
                ? undefined
                : {
                    keys: search?.fields.map((item) => item.name).join(","),
                    value: searchFormData,
                  }
            }
            order={orderData}
            page={page_number}
            rowsPerPage={limit}
            setOrder={setOrderData}
            list_loading={itemsLoading}
            hidePagination={hide?.pagination === true}
            query={query}
          />
        </Box>
      </PermissionsManager>
    )
  );
};

export default List;
