const list = {
    header: [
        {
            id: 'number',
            label: 'Lot ID',
            align: 'left'
        },
        {
            id: 'max_tickets',
            label: 'Total # tickets per lot',
            align: 'left'
        },
        {
            id: 'remaining_tickets',
            label: 'Unsold tickets',
            align: 'left'
        },
        {
            id: 'gross_revenue',
            label: 'Gross Revenue',
            align: 'left'
        },
        {
            id:'winner_count',
            label: 'Tickets Won',
            align: 'left'
        },
        {
            id: 'total_prize_won',
            label: 'Value of prizes Won',
            align: 'left'
        },
        {
            id: 'winning_tickets',
            label: 'Total Winning tickets',
            align: 'left'
        },
        {
            id: 'remaining_winning_tickets',
            label: 'Remaining winning tickets',
            align: 'left'
        },
        {
            id: 'total_prize',
            label: 'Total prize',
            align: 'left'
        },
        {
            id: 'Draw.img_title.en',
            label: 'Draw Title',
            align: 'left',
            order: false
        },
        {
            id: 'sold_out',
            label: 'Sold out',
            align: 'left',
            switch: true,
            disabled: true
        }
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'remove', 'update', { name: 'probabilities', type: 'link', to: ':id/probabilities', permission: 'list', icon: 'ShuffleOn' }]
};

const show = {
    fields: [
        {
            type: 'text',
            name: 'number',
            label: 'Lot Name'
        },
        {
            type: 'number',
            name: 'max_tickets',
            label: 'Total Number of tickets for this lot'
        },
        {
            type: 'number',
            name: 'winning_tickets',
            label: 'Total Number of winning tickets for this lot'
        },
        {
            type: 'number',
            name: 'total_prize',
            label: 'Total prize'
        },
    ],
    permission: 'show'
};

const create = {
    fields: [
        {
            type: 'text',
            name: 'number',
            label: 'Lot Name'
        },
        {
            type: 'number',
            name: 'max_tickets',
            label: 'Total Number of tickets for this lot'
        },
        {
            type: 'number',
            name: 'winning_tickets',
            label: 'Total Number of winning tickets for this lot'
        }
    ],
    permission: 'create'
};

const edit = {
    fields: [
        {
            type: 'text',
            name: 'number',
            label: 'Lot Name'
        },
        {
            type: 'number',
            name: 'max_tickets',
            label: 'Total Number of tickets for this lot'
        },
        {
            type: 'number',
            name: 'winning_tickets',
            label: 'Total Number of winning tickets for this lot'
        }
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: 'number',
            name: 'max_tickets'
        },
        {
            type: 'number',
            name: 'winning_tickets'
        },
    ]
};

const filter = {
    fields: [
        {
            type: 'select',
            async: true,
            name: 'draw_id',
            route: 'draws',
            label: 'Draw',
        }
    ]
};

const data = { list, show, create, edit, search, filter };

export default data;