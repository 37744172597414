import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import the timezone plugin
import { Box, Divider, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useState } from "react";

// Extend dayjs with the timezone and utc plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const Date = ({ field, value, onChange, show }) => {
  const {
    label,
    name,
    past_disabled = false,
    future_disabled = false,
    format = "date",
  } = field;
  const [cleared, setCleared] = useState(false);

  return show ? (
    <Box mb={3}>
      <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
        {field.label}
      </Typography>
      <Typography mb={3}>{value}</Typography>
      <Divider />
    </Box>
  ) : (
    <div className="form-control">
      <label
        className={`form-label ${field.required ? "form-input-required" : ""}`}
        htmlFor={name}
      >
        {label}
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {format == "datetime" && (
          <DateTimePicker
            required={field?.required}
            value={value ? dayjs.tz(value, 'Asia/Dubai') : null}
            name={name}
            id={name}
            className="form-input"
            sx={{
              "& input": {
                borderRadius: 0, // Set border-radius to 0 for the input
                paddingY: 0,
                fontSize: "14px",
                lineHeight: "24px",
                height: "34px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 0, // Set border-radius to 0 for the outlined input border
                paddingY: 0,
              },
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setCleared(true);
                  onChange({ target: { id: name, value: null } });
                },
              },
            }}
            disablePast={past_disabled}
            disableFuture={future_disabled}
            onChange={(newValue) => {
              const date = newValue.format("YYYY-MM-DD HH:mm");
              onChange({ target: { id: name, value: date } });
            }}
            label=""
            format="YYYY-MM-DD HH:mm"
          />
        )}
        {format == "date" && (
          <DatePicker
            isClearable={true}
            required={field?.required}
            value={value ? dayjs.tz(value, 'Asia/Dubai') : null}
            name={name}
            id={name}
            className="form-input"
            disablePast={past_disabled}
            disableFuture={future_disabled}
            sx={{
              "& input": {
                borderRadius: 0, // Set border-radius to 0 for the input
                paddingY: 0,
                fontSize: "14px",
                lineHeight: "24px",
                height: "34px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 0, // Set border-radius to 0 for the outlined input border
                paddingY: 0,
              },
            }}
            onChange={(newValue) => {
              const date = newValue.format("YYYY-MM-DD");
              onChange({ target: { id: name, value: date } });
            }}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setCleared(true);
                  onChange({ target: { id: name, value: null } });
                },
              },
            }}
            label=""
            format="YYYY-MM-DD"
          />
        )}
        {format == "time" && (
          <TimePicker
            required={field?.required}
            value={value ? dayjs.tz(`0000-00-00T${value}`, 'Asia/Dubai') : null}
            views={["hours", "minutes"]}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setCleared(true);
                  onChange({ target: { id: name, value: null } });
                },
              },
            }}
            sx={{
              "& input": {
                borderRadius: 0, // Set border-radius to 0 for the input
                paddingY: 0,
                fontSize: "14px",
                lineHeight: "24px",
                height: "34px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 0, // Set border-radius to 0 for the outlined input border
                paddingY: 0,
              },
            }}
            name={name}
            id={name}
            className="form-input"
            disablePast={past_disabled}
            disableFuture={future_disabled}
            onChange={(value) => {
              const date = value.format("HH:mm");
              onChange({ target: { id: name, value: date } });
            }}
            label=""
            format="HH:mm"
          />
        )}
      </LocalizationProvider>
      {field?.description && (
        <i className={`form-description`}>{field?.description}</i>
      )}
    </div>
  );
};

export default Date;
