import { useEffect, useState } from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../layouts/Breadcrumbs';

import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  HighlightOffOutlined,
} from '@mui/icons-material';
import Input from '../../layouts/Input';
import PermissionsManager from '../../layouts/PermissionsManager';
import { authorized } from '../../../utils/utils';
import { list, get, create, edit, remove, bulk_remove } from '../../../features/lots/actions';

const Show = () => {
  const dispatch = useDispatch();
  let { id, lot_id } = useParams();
  const page = 'Lots';
  const fields = useSelector(
    (state) => state[page.toString().toLowerCase()].properties.show.fields,
  );
  const user = useSelector((state) => state.auth.user);
  const hide = useSelector(
    (state) => state[page.toString().toLowerCase()].properties.show.hide,
  );
  const item = useSelector(
    (state) => state[page.toString().toLowerCase()].item,
  );

  const getValueByName = (name, obj) => {
    const keys = name.split('.');
    let value = obj;

    for (const key of keys) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return undefined;
      }
    }

    return value;
  };

  useEffect(() => {
    dispatch(get(lot_id));
  }, []);

  return (
    user?.Permissions && (
      <PermissionsManager
        action='show'
        name={page.toLowerCase()}
        permissions={user?.Permissions}
      >
        <Box height='100%'>
          {hide?.breadcrumbs !== true && (
            <Breadcrumbs page={page.toString()} text={'Show'} />
          )}
          {/* Header */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent='space-between'
            alignItems='center'
            mb={4}
          >
            {/* Title */}
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              alignSelf={{ xs: 'start', sm: 'center' }}
            >
              {hide?.back !== true &&
                authorized(user.Permissions, page.toLowerCase(), 'list') && (
                  <IconButton
                    component={RouterLink}
                    to={`/cms/draws/${id}/${page.toString().toLowerCase()}`}
                    aria-label='arrow-back'
                    sx={{ border: '2px solid', borderRadius: '50%' }}
                    disableRipple
                    size='small'
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
              {hide?.title !== true && (
                <Typography variant='h5' mr={1}>
                  {'Show'}
                </Typography>
              )}
            </Stack>

            {/* Buttons */}
            <Stack
              direction='row'
              spacing={2}
              mt={{ xs: 2, sm: 0 }}
              alignSelf={{ xs: 'end', sm: 'center' }}
            >
              {hide?.edit !== true &&
                authorized(user.Permissions, page.toLowerCase(), 'form') && (
                  <Button
                    component={RouterLink}
                    to='edit'
                    variant='contained'
                    startIcon={<EditIcon />}
                    sx={{ borderRadius: 0 }}
                  >
                    Edit
                  </Button>
                )}
            </Stack>
          </Stack>

          {/* Body */}
          <Paper
            elevation={0}
            mt={2}
            sx={{
              width: '100%',
              borderRadius: 0,
              height: { md: '75vh', xs: '60vh' },
              padding: 4,
            }}
          >
            {item && (
              <Box
                sx={{
                  width: '100%',
                  overflowY: 'auto',
                  borderRadius: 0,
                  height: '95%',
                }}
              >
                {fields.map((field, i) => (
                  <Input
                    field={field}
                    value={
                      field?.child?.length > 0
                        ? item[field.name][field.child]
                        : getValueByName(field.name, item)
                    }
                    show={true}
                    video={
                      field.type === 'background' ? item?.isVideo : undefined
                    }
                  />
                ))}
              </Box>
            )}
          </Paper>
        </Box>
      </PermissionsManager>
    )
  );
};

export default Show;
