const list = {
    header: [
        {
            id: 'image',
            display: true,
            label: 'Image',
            align: 'left'
        },
        {
            id: 'img_title.en',
            label: 'Name(en)',
            orderBy: 'img_title.en',
            align: 'left'
        },
        {
            id: 'price',
            label: 'Price',
            align: 'left'
        },
        {
            id: 'extra_ball_prize',
            label: 'Prize',
            align: 'left'
        },
        {
            id: 'date',
            label: 'Draw Result Date',
            align: 'left',
            orderBy: 'date',
        },
        {
            id: 'total_players',
            label: 'Total # Players',
            align: 'left',
            order: false
        },
        {
            id: 'total_tickets',
            label: 'Total # of Tickets SOLD',
            align: 'left',
            order: false
        },
        {
            id: 'publish',
            label: 'publish',
            align: 'left',
            switch: true
        }
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'remove', 'update', { name: 'lots', type: 'link', to: ':id/lots', permission: 'list', icon: 'SettingsInputSvideo' }]
};

const show = {
    fields: [
        {
            type: 'number',
            name: 'number',
            label: 'Number'
        },
        {
            type: 'image',
            name: 'image',
            label: 'Draw Image'
        },
        {
            type: 'mixed',
            name: 'img_title',
            label: 'Draw Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                },
            ],
        },
        {
            type: 'number',
            name: 'price',
            label: 'Draw Price (AED)'
        },
        {
            type: 'number',
            name: 'extra_ball_prize',
            label: 'Super Prize (AED)'
        },
        {
            type: 'number',
            name: 'prize',
            label: 'Grand Prize (AED)'
        },
        {
            type: 'number',
            name: 'second_prize',
            label: 'Second prize (AED)'
        },
        {
            type: 'number',
            name: 'third_prize',
            label: 'Third_ prize (AED)'
        },
        {
            type: 'number',
            name: 'ticket_prize',
            label: 'Scrtach & Win front end',
        },
        // {
        //     type: 'number',
        //     name: 'extra_ball_price',
        //     label: '6th ball price'
        // },
        {
            type: 'number',
            name: 'img_width',
            label: 'Image width (px)'
        },
        {
            type: 'number',
            name: 'img_height',
            label: 'Image height(px)'
        },
        {
            type: 'number',
            name: 'distributed',
            label: 'Distributed Amount (AED)'
        },
        {
            type: 'text',
            name: 'winner_video',
            label: 'Winner Video URL'
        },
        {
            type: 'text',
            name: 'winner_name',
            label: 'Winner name'
        },
        {
            type: 'text',
            name: 'formated_date',
            label: 'Draw date'
        },
        {
            type: 'text',
            name: 'formated_from',
            label: 'Draw End Date'
        },
        {
            type: 'text',
            name: 'result',
            label: 'Result'
        },
        // {
        //     type: 'number',
        //     name: 'extra_ball',
        //     label: '6th Ball Result'
        // }
    ],
    permission: 'show',
    hide: {
        end: true,
        check: true,
    },
    auto_sync: true
};

const create = {
    fields: [
        {
            type: 'image',
            name: 'image',
            label: 'Draw Image',
            required: true
        },
        {
            type: 'mixed',
            name: 'img_title',
            label: 'Draw Title',
            required: true,
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true
                },
            ],
        },
        {
            type: 'number',
            name: 'price',
            label: 'Draw Price (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'extra_ball_prize',
            label: 'Super Prize (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'prize',
            label: 'Grand Prize (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'second_prize',
            label: 'Second prize (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'third_prize',
            label: 'Third prize (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'ticket_prize',
            label: 'Scrtach & Win front end',
            required: true
        },
        // {
        //     type: 'number',
        //     name: 'extra_ball_price',
        //     label: '6th ball price',
        //     required: true
        // },
        {
            type: 'number',
            name: 'distributed',
            label: 'Distributed Amount (AED)'
        },
        {
            type: 'text',
            name: 'winner_video',
            label: 'Winner Video URL',
        },
        {
            type: 'text',
            name: 'winner_name',
            label: 'Winner name'
        },
        {
            type: 'date',
            name: 'date',
            label: 'Draw Result Date',
            required: true,
            format: 'datetime',
            past_disabled: true,
        },
        {
            type: 'date',
            name: 'from',
            label: 'Draw End Date',
            required: true,
            format: 'datetime',
            past_disabled: true
        },
    ],
    permission: 'create'
};

const edit = {
    fields: [
        {
            type: 'image',
            name: 'image',
            label: 'Draw Image',
            required: true
        },
        {
            type: 'mixed',
            name: 'img_title',
            label: 'Draw Title',
            required: true,
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true
                },
            ],
        },
        {
            type: 'number',
            name: 'price',
            label: 'Draw Price (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'extra_ball_prize',
            label: 'Super Prize (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'prize',
            label: 'Grand Prize (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'second_prize',
            label: 'Second prize (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'third_prize',
            label: 'Third prize (AED)',
            required: true
        },
        {
            type: 'number',
            name: 'ticket_prize',
            label: 'Scrtach & Win front end',
            required: true
        },
        // {
        //     type: 'number',
        //     name: 'extra_ball_price',
        //     label: '6th ball price',
        //     required: true
        // },
        {
            type: 'number',
            name: 'distributed',
            label: 'Distributed Amount (AED)'
        },
        {
            type: 'text',
            name: 'winner_video',
            label: 'Winner Video URL',
        },
        {
            type: 'text',
            name: 'winner_name',
            label: 'Winner name'
        },
        {
            type: 'date',
            name: 'date',
            label: 'Draw Result Date',
            required: true,
            format: 'datetime',
            past_disabled: true
        },
        {
            type: 'date',
            name: 'from',
            label: 'Draw End Date',
            required: true,
            format: 'datetime',
            past_disabled: true
        },
        {
            type: 'text',
            name: 'result',
            label: 'Winning Balls',
            required: false,
            placeholder: '20,5,33,12,7,15'
        },
        // {
        //     type: 'number',
        //     name: 'extra_ball',
        //     label: '6th Ball Result'
        // }
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'img_title.en'
        },
    ]
};

const data = { list, show, create, edit, search };

export default data;