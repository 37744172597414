const list = {
    header: [
        {
            id: 'id',
            label: 'Ticket Number',
            align: 'left'
        },
        {
            id: 'message',
            label: 'Amount',
            align: 'left'
        },
        {
            id: 'Lot.number',
            label: 'Lot number',
            align: 'left'
        },
        {
            id: 'user.fullName',
            label: 'Name',
            align: 'left'
        },
        {
            id: 'user.fullMobile',
            label: 'Mobile',
            align: 'left'
        },
        {
            id: 'user.email',
            label: 'Email',
            align: 'left'
        },
        {
            id: 'createdAt',
            label: 'Date',
            align: 'left'
        },
        {
            id: 'reference',
            label: 'Reference',
            align: 'left'
        },
        {
            id: 'user.iban',
            label: 'IBAN number',
            align: 'left'
        },
        {
            id: 'paid',
            label: 'Paid',
            align: 'left',
            switch: true
        },
        {
            id: 'toClaim',
            label: 'To be claimed',
            align: 'left',
            switch: true,
            disabled: true
        },
    ],
    hide: {
        create: true
    },
    permission: 'list',
    actions: ['update']
};

const edit = {
    fields: [
        {
            type: 'text',
            name: 'reference',
            label: 'Reference'
        }
    ],
    permission: 'edit'
}

const search = {
    fields: [
        {
            type: 'text',
            name: '$user.first_name$'
        },
        {
            type: 'text',
            name: '$user.last_name$'
        },
        {
            type: 'text',
            name: '$user.mobile_number$'
        },
        {
            type: 'text',
            name: '$user.email$'
        },
        {
            type: 'text',
            name: '$user.iban$'
        },
        {
            type: 'text',
            name: 'reference'
        }
    ]
};

const filter = {
    fields: [
        {
            type: "select",
            name: "toClaim",
            label: "To be claimed",
            required: true,
            values: [
                { value: true, label: 'To Claim' },
                { value: false, label: 'Not To Claim' }
            ],
        },
    ]
}

const data = { list, search, edit, filter };

export default data;