const list = {
    header: [
        {
            id: 'user.fullName',
            label: 'Name',
            align: 'left'
        },
        {
            id: 'user.iban',
            label: 'IBAN Number',
            align: 'left'
        },
        {
            id: 'amount',
            label: 'Amount',
            align: 'left'
        },
        {
            id: 'status',
            label: 'Status',
            align: 'left'
        },

    ],
    permission: 'list',
    hide: {
        create: true
    },
    actions: ['update']
};

const edit = {
    fields: [
        {
            type: "select",
            name: "status",
            label: "Status",
            required: false,
            values: [
                { value: 'Pending', label: 'Pending' },
                { value: 'Approved', label: 'Approved' },
                { value: 'Rejected', label: 'Rejected' },
                { value: 'Completed', label: 'Completed' },
            ],
        },
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: 'text',
            name: '$user.first_name$'
        },
        {
            type: 'text',
            name: '$user.last_name$'
        },
        {
            type: 'text',
            name: '$user.iban$'
        },
        {
            type: 'text',
            name: 'status'
        }
    ]
};

const filter = {
    fields: [
        {
            type: "select",
            name: "status",
            label: "Status",
            required: false,
            values: [
                { value: 'Pending', label: 'Pending' },
                { value: 'Approved', label: 'Approved' },
                { value: 'Rejected', label: 'Rejected' },
                { value: 'Completed', label: 'Completed' },
            ],
        }
    ]
}

const data = { list, edit, search, filter };

export default data;