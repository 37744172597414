const list = {
    header: [
        {
            id: 'Draw.img_title.en',
            label: 'Draw Title',
            align: 'left'
        },
        {
            id:'user.id',
            label: 'User ID',
            align: 'left'
        },
        {
            id:'user.fullName',
            label: 'User name',
            align: 'left'
        },
        {
            id: 'user.fullMobile',
            label: 'Mobile number',
            align: 'left'
        },
        {
            id: 'user.email',
            label: 'Email',
            align: 'left'
        },
        {
            id: 'quantity',
            label: 'Quantity',
            align: 'left',
        },
        {
            id: 'unit_price',
            label: 'Image price',
            align: 'left',
        },
        {
            id: 'vat',
            label: 'Vat',
            align: 'left',
        },
        {
            id: 'total',
            label: 'Total',
            align: 'left',
        },
        {
            id: 'gateway',
            label: 'Gateway',
            align: 'left'
        },
        {
            id: 'wallet',
            label: 'Wallet',
            align: 'left'
        },
        {
            id: 'transaction_date',
            label: 'Date',
            align: 'left',
            orderBy: 'createdAt',
        },
        {
            id: 'payment_status',
            label: 'Status',
            align: 'left',
        },
    ],
    hide: {
        create: true,
    },
    actions: ['show'],
};

const show = {
    fields: [
        {
            type: "image",
            name: 'image',
            label: "Image",
        },
        {
            type: "text",
            name: 'token',
            label: 'Ref',
        },
        {
            type: 'number',
            name: 'quantity',
            label: 'Quantity',
        },
        {
            type: 'number',
            name: 'unit_price',
            label: 'Image price',
        },
        {
            type: 'text',
            name: 'vat',
            label: 'Vat',
        },
        {
            type: 'number',
            name: 'total',
            label: 'Total',
        },
        {
            type: 'text',
            name: 'formated_date',
            label: 'Date',
        },
        {
            type: 'text',
            name: 'payment_status',
            label: 'Status',
        },
        {
            type: 'text',
            name: 'user.fullName',
            label: 'Status',
        },
        {
            type: 'text',
            name: 'user.fullMobile',
            label: 'Status',
        },
        {
            type: 'mixed',
            name: 'Draw.img_title',
            label: 'Draw Title',
            elements: [
                {
                  type: 'text',
                  name: 'en',
                  label: 'English',
                },
                {
                  type: 'text',
                  name: 'ar',
                  label: 'Arabic',
                },
            ]
        }
    ]
};

const filter = {
    fields: [
        {
            type: 'number',
            name: '$user.id$',
            label: 'User ID'
        },
        {
            type: "select",
            name: "status",
            label: "Status",
            values: [
                { value: 0, label: 'Pending' },
                { value: 1, label: 'Success' },
                { value: 2, label: 'Failed' }
            ]
        }
    ]
};

const search = {
    fields: [
        {
            type: "text",
            name: "$user.first_name$",
        },
        {
            type: "text",
            name: "$user.last_name$",
        },
        {
            type: "text",
            name: "$user.email$",
        },
        {
            type: "text",
            name: "$user.mobile_number$"
        },
        {
            type: 'text',
            name: 'token'
        }
    ]
}

const data = { list, show, filter, search };

export default data;