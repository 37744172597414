import { IconButton } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom';
import React from 'react'
import Icon from '../layouts/Icon';

const ActionLink = ({ to, icon, sx, variant, disableRipple, id }) => {
    return (
        <IconButton
            component={RouterLink}
            to={to.replace(':id', id)}
            sx={sx ?? {
                padding: 0,
                '&:hover': { color: '#1976d2' },
                transition: 'all 0.2s',
            }}
            variant={variant ?? 'contained'}
            disableRipple={disableRipple ?? true}
        >
            <Icon type={icon} />
        </IconButton>
    )
}

export default ActionLink