import { createAsyncThunk } from "@reduxjs/toolkit";
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from "../../utils/api";
import { setAlert } from "../alerts/actions";
import { list as LotsList } from '../lots/actions';
import moment from 'moment';

export const list = createAsyncThunk("draws/list", async (params = { page: 0, limit: 10, orderBy: null, orderDirection: null, filter: null, search: null }) => {
    try {
        const page = params.page ? params.page : 0
        const limit = params.limit ? params.limit : 10
        const res = await api.get(`/admin/draws`, { params });
        console.log(res);
        console.log(page, limit)
        return { ...res.data, page, limit };
    } catch (error) {
        console.log(error);
    }
});

export const create = createAsyncThunk("draws/create", async ({ data, navigate }, { dispatch }) => {
    try {
        const res = await api.post('/admin/draws', data);
        dispatch(list());
        dispatch(setAlert({ message: 'Draw Added successfully', alertType: 'success' }))
        if (navigate)
            navigate(`/cms/draws`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const get = createAsyncThunk("draws/get", async (id, { dispatch }) => {
    try {
        const res = await api.get(`/admin/draws/${id}`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const edit = createAsyncThunk("draws/edit", async ({ id, data, navigate }, { dispatch }) => {
    try {
        await api.put(`/admin/draws/${id}`, data);
        dispatch(setAlert({ message: 'Draw updated successfully', alertType: 'success' }))
        dispatch(list());
        if (navigate)
            navigate(`/cms/draws`);
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const remove = createAsyncThunk("draws/remove", async (id, { dispatch }) => {
    try {
        await api.delete(`/admin/draws/${id}`);
        dispatch(setAlert({ message: 'Draw removed successfully', alertType: 'error' }))
        dispatch(list());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const bulk_remove = createAsyncThunk("draws/bulk-remove", async ({ data }, { dispatch }) => {
    try {
        await api.post(`/admin/draws/delete`, data);
        dispatch(setAlert({ message: 'Draws removed successfully', alertType: 'error' }))
        dispatch(list());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const end = createAsyncThunk("draws/end", async ({ id, data, navigate }, { dispatch }) => {
    try {
        const res = await api.post('/admin/draws/end', data);
        dispatch(setAlert({ message: 'Draw ended successfully', alertType: 'success' }))
        dispatch(get(id));
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const check = createAsyncThunk("draws/check-winners", async ({ id, data, navigate }, { dispatch }) => {
    try {
        const res = await api.post('/admin/draws/check-winners', data, {responseType: 'blob'});
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `users_${moment().format('DD/MM/YYYY')}_${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});