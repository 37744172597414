import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, get, edit, } from '../../../features/currency/actions';

const index = () => {

    const page = "Currency";

    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, get, edit }]} />
        </div>
    )
}

export default index;