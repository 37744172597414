import { Box, Divider, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'

const Number = ({ field, value, onChange, show }) => {
    const { label, name } = field
    const inputRef = useRef(null);

    useEffect(() => {
        if(!show) {
            const handleWheel = (event) => {
              if (inputRef.current === document.activeElement) {
                event.preventDefault();
              }
            };
        
            inputRef.current.addEventListener('wheel', handleWheel, { passive: false });
        }
      }, [show]);

    return (
        show ?
            <Box mb={3} >
                <Typography variant="h6" component="h6" mb={2} fontWeight="bold">
                    {field.label}
                </Typography>
                <Typography mb={3} >
                    {value}
                </Typography>
                <Divider />
            </Box> :
            <div className="form-control">
                <label className={`form-label ${field.required ? "form-input-required" : ''}`} htmlFor={name}>{label}</label>
                <input className="form-input" type="number" ref={inputRef} required={field?.required} name={name} id={name} min={field.min ?? 0} value={value} step='any' onChange={(e) => onChange(e)} />
            </div>
    )
}

export default Number