import { configureStore } from '@reduxjs/toolkit';

import faqReducer from './features/faq/slice';
import authReducer from './features/auth/slice';
import smtpReducer from './features/smtp/slice';
import lotsReducer from './features/lots/slice';
import rolesReducer from './features/roles/slice';
import termsReducer from './features/terms/slice';
import linksReducer from './features/links/slice';
import usersReducer from './features/users/slice';
import drawsReducer from './features/draws/slice';
import alertsReducer from './features/alerts/slice';
import adminsReducer from './features/admins/slice';
import findusReducer from './features/findus/slice';
import supportReducer from './features/support/slice';
import sidebarReducer from './features/sidebar/slice';
import sectionReducer from './features/section/slice';
import aboutUsReducer from './features/about-us/slice';
import currencyReducer from './features/currency/slice';
import contactUsReducer from './features/contact-us/slice';
import permissionsReducer from './features/permissions/slice';
import drawwinnersReducer from './features/drawwinners/slice';
import informativeReducer from './features/informative/slice';
import transactionsReducer from './features/transactions/slice';
import probabilitiesReducer from './features/probabilities/slice';
import privacyPolicyReducer from './features/privacy-policy/slice';
import claimRequestsReducer from './features/claim-requests/slice';
import scratchwinnersReducer from './features/scratchwinners/slice';

export const store = configureStore({
  // Add the slices to the store so they can be accessed by any components on the app
  reducer: {
    faq: faqReducer,
    auth: authReducer,
    smtp: smtpReducer,
    lots: lotsReducer,
    roles: rolesReducer,
    terms: termsReducer,
    links: linksReducer,
    users: usersReducer,
    draws: drawsReducer,
    admins: adminsReducer,
    alerts: alertsReducer,
    support: supportReducer,
    sidebar: sidebarReducer,
    section: sectionReducer,
    'find us': findusReducer,
    currency: currencyReducer,
    'about us': aboutUsReducer,
    'contact us': contactUsReducer,
    permissions: permissionsReducer,
    drawwinners: drawwinnersReducer,
    informative: informativeReducer,
    transactions: transactionsReducer,
    probabilities: probabilitiesReducer,
    scratchwinners: scratchwinnersReducer,
    'privacy policy': privacyPolicyReducer,
    'claim requests': claimRequestsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
