import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
    const location = useLocation();
    const query = location.search;
    let params = new URLSearchParams(location.search);

    const getAllQueryParams = () => {
        if (params.size == 0) return null
        const allParams = {};
        for (const [key, value] of params.entries()) {
            allParams[key] = value;
        }
        return allParams;
    };

    params = getAllQueryParams();


    // Helper function to get the value of a specific query parameter
    const getQueryParam = (paramName) => params ? params[paramName] : null;

    return {
        params,
        getQueryParam,
        query
    };
};

export default useQueryParams;
