import { useEffect, useState } from "react";
import { Link, Link as RouterLink } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Footer from "./Footer";
import ActionLink from "../actions/ActionLink";
import ActionButton from "../actions/ActionButton";
import Action from "../actions/Action";
import NoRows from "./NoRows";

export default function StickyHeadTable({
  rows,
  columns,
  onDelete,
  edit,
  dispatch,
  list,
  count,
  name,
  permissions,
  authorized,
  actions,
  bulk,
  bulkToggle,
  filter,
  search,
  order,
  setOrder,
  page,
  rowsPerPage,
  hidePagination,
  list_loading,
  query,
}) {
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [item, setItem] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (id) => {
    setItem(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    onDelete(item);
  };

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    bulkToggle([], true);
    const orderBy = format(order, "id");
    const orderDirection = format(order, "direction");
    console.log(filter);
    const data = {};
    for (const key in filter) {
      if (filter[key]) {
        data[key] = filter[key];
      }
    }
    dispatch(
      list({
        page: newPage,
        limit: rowsPerPage,
        filter: data,
        orderBy: orderBy.length > 0 ? orderBy : undefined,
        orderDirection: orderDirection.length > 0 ? orderDirection : undefined,
        search,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(event.target.value);
    // setPage(0);
    bulkToggle([], true);
    const orderBy = format(order, "id");
    const orderDirection = format(order, "direction");
    const data = {};
    for (const key in filter) {
      if (filter[key]) {
        data[key] = filter[key];
      }
    }
    dispatch(
      list({
        page: 0,
        limit: event.target.value,
        filter: data,
        orderBy: orderBy.length > 0 ? orderBy : undefined,
        orderDirection: orderDirection.length > 0 ? orderDirection : undefined,
        search,
      })
    );
  };

  const format = (arr, key) => {
    let value = [];
    for (const item of arr) {
      value.push(item[key]);
    }
    return value.join(",");
  };

  const getChildValue = (row, value) => {
    if (value.split(".").length > 1) {
      let res = row;
      for (const key of value.split(".")) {
        if (res && res[key]) {
          res = res[key];
        } else {
          res = null;
        }
      }
      return res;
    }
    return row[value];
  };

  // useEffect(() => {
  //     setPage(0)
  // }, [filter])

  // useEffect(() => {
  //     setPage(0)
  // }, [search])

  return (
    <>
      <Paper
        elevation={0}
        mt={2}
        sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: 0 }}
        >
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Keep</Button>
            <Button onClick={handleConfirm} autoFocus color="error">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
        <TableContainer sx={{ maxHeight: 520 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow key={1}>
                {actions?.includes("bulk remove") &&
                  authorized(permissions, name, "remove") && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={bulk.length === rows.length && rows.length > 0}
                        onChange={() => {
                          bulkToggle(
                            bulk.length === rows.length
                              ? []
                              : rows?.map((item) => item.id),
                            true
                          );
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        disableRipple={true}
                      />
                    </TableCell>
                  )}
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.order !== false ? (
                      <Stack
                        sx={{
                          alignItems: "center",
                          flexDirection: "row",
                          textTransform: "none",
                          justifyContent: "flex-start",
                          padding: 0,
                          margin: 0,
                          color: "black",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          const id = column.orderBy
                            ? column.orderBy
                            : column.id;
                          const s = order.filter((item) => item.id === id);
                          let orderBy = [];
                          if (s.length === 0) {
                            setOrder([...order, { id: id, direction: "ASC" }]);
                            orderBy = [...order, { id: id, direction: "ASC" }];
                          }
                          if (s.length > 0 && s[0].direction === "ASC") {
                            setOrder([
                              ...order.filter((item) => item.id !== id),
                              { id: id, direction: "DESC" },
                            ]);
                            orderBy = [
                              ...order.filter((item) => item.id !== id),
                              { id: id, direction: "DESC" },
                            ];
                          }
                          if (s.length > 0 && s[0].direction === "DESC") {
                            setOrder([
                              ...order.filter((item) => item.id !== id),
                            ]);
                            orderBy = [
                              ...order.filter((item) => item.id !== id),
                            ];
                          }
                          bulkToggle([], true);
                          const filterData = {};
                          for (const key in filter) {
                            if (filter[key]) {
                              filterData[key] = filter[key];
                            }
                          }
                          // console.log({ page: 0, limit: rowsPerPage, orderBy: format(orderBy, 'id'), orderDirection: format(orderBy, 'direction') })
                          dispatch(
                            list({
                              page: 0,
                              limit: rowsPerPage,
                              orderBy: format(orderBy, "id"),
                              orderDirection: format(orderBy, "direction"),
                              filter: filterData,
                              search,
                            })
                          );
                          // setPage(0);
                        }}
                        component={Button}
                        variant="text"
                      >
                        <Box sx={{ display: "inline-block" }}>
                          {column.label}
                        </Box>
                        {order.filter(
                          (item) =>
                            item.id ===
                            (column.orderBy ? column.orderBy : column.id)
                        ).length > 0 &&
                          order.filter(
                            (item) =>
                              item.id ===
                              (column.orderBy ? column.orderBy : column.id)
                          )[0]?.direction === "ASC" && <ArrowDropUpIcon />}
                        {order.filter(
                          (item) =>
                            item.id ===
                            (column.orderBy ? column.orderBy : column.id)
                        ).length > 0 &&
                          order.filter(
                            (item) =>
                              item.id ===
                              (column.orderBy ? column.orderBy : column.id)
                          )[0]?.direction === "DESC" && <ArrowDropDownIcon />}
                      </Stack>
                    ) : (
                      <>{column.label}</>
                    )}
                  </TableCell>
                ))}
                {actions && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!list_loading ? (
                <>
                  {rows.length > 0 ? (
                    rows?.map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {actions?.includes("bulk remove") &&
                          authorized(permissions, name, "remove") && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={bulk.includes(row.id)}
                                onChange={() => bulkToggle(row.id)}
                                inputProps={{ "aria-label": "controlled" }}
                                disableRipple={true}
                              />
                            </TableCell>
                          )}
                        {columns.map((column) => {
                          const value = getChildValue(row, column.id);
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : column.switch ? (
                                <Switch
                                  checked={value}
                                  disabled={column.disabled}
                                  onChange={(e) => {
                                    dispatch(
                                      edit({
                                        id: row.id,
                                        data: { ...row, [column.id]: !value },
                                      })
                                    );
                                  }}
                                />
                              ) : column.link ? (
                                <Link
                                  to={`/cms/${column.route}/${
                                    getChildValue(row, column.link_id) ?? value
                                  }`}
                                >
                                  {value}
                                </Link>
                              ) : column.display ? (
                                column.type === "background" ? (
                                  row?.isVideo ? (
                                    <video
                                      src={value}
                                      alt="item"
                                      autoPlay
                                      muted
                                      controls={false}
                                      className="img-thumbnail contain"
                                    ></video>
                                  ) : (
                                    <img
                                      src={value}
                                      alt="item"
                                      className="img-thumbnail contain"
                                    />
                                  )
                                ) : (
                                  <img
                                    src={value}
                                    alt="item"
                                    className="img-thumbnail contain"
                                  />
                                )
                              ) : column.maxLength ? (
                                `${value.substring(0, column.maxLength)}...`
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                        {actions && (
                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              justifyContent="end"
                            >
                              {actions.map((action, i) => (
                                <>
                                  {action === "show" &&
                                    authorized(permissions, name, "show") && (
                                      <ActionLink
                                        key={i}
                                        to={`${row.id}${query}`}
                                        icon="Visibility"
                                      />
                                    )}
                                  {action === "update" &&
                                    authorized(permissions, name, "form") && (
                                      <ActionLink
                                        key={i}
                                        to={`${row.id}/edit${query}`}
                                        icon="Edit"
                                      />
                                    )}
                                  {action === "remove" &&
                                    authorized(permissions, name, "remove") && (
                                      <ActionButton
                                        key={i}
                                        click={() => handleClickOpen(row.id)}
                                        icon="Delete"
                                        sx={{
                                          padding: 0,
                                          "&:hover": { color: "red" },
                                          transition: "all 0.2s",
                                        }}
                                      />
                                    )}
                                  {action instanceof Object &&
                                    authorized(
                                      permissions,
                                      action.name ?? name,
                                      action.permission
                                    ) && (
                                      <Action
                                        key={i}
                                        action={action}
                                        id={row.id}
                                      />
                                    )}
                                </>
                              ))}
                            </Stack>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow sx={{ height: 320 }}>
                      <TableCell
                        colSpan={columns.length + 1 + actions.length + 1}
                      >
                        <NoRows />
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow padding="none">
                  <TableCell
                    colSpan={columns.length + 1 + actions.length + 1}
                    padding="none"
                    sx={{ height: 320, position: "relative" }}
                  >
                    <LinearProgress
                      sx={{ width: "100%", position: "absolute", top: 0 }}
                    />
                    <Stack
                      sx={{
                        alignItems: "center",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h6" color="initial">
                        Loading...
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!hidePagination && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <Footer />
    </>
  );
}
