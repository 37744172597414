// other imports
import Box from '@mui/material/Box';
import Main from './components/pages/Main';
import { Route, Routes } from 'react-router-dom';
import List from './components/pages/default/List';
import Show from './components/pages/default/Show';
import Form from './components/pages/default/Form';
import Order from './components/pages/default/Order';
import { createTheme, ThemeProvider } from '@mui/material';

// Custom Pages
import Login from './components/pages/Login';
import FAQPage from './components/pages/faq';
import SMTPPage from './components/pages/smtp';
import RolesPage from './components/pages/roles';
import LinksPage from './components/pages/links';
import TermsPage from './components/pages/terms';
import Alerts from './components/layouts/Alerts';
import UsersPage from './components/pages/users';
import DrawsPage from './components/pages/draws';
import AdminsPage from './components/pages/admins';
import FindUsPage from './components/pages/findus';
import LotsList from './components/pages/lots/List';
import LotsShow from './components/pages/lots/Show';
import LotsForm from './components/pages/lots/Form';
import SupportPage from './components/pages/support';
import NotFound from './components/layouts/NotFound';
import SectionPage from './components/pages/section';
import RolesForm from './components/pages/roles/Form';
import AboutUsPage from './components/pages/about-us';
import Profile from './components/pages/auth/Profile';
import AuthRoute from './components/layouts/AuthRoute';
import CurrencyPage from './components/pages/currency';
import ContactUsPage from './components/pages/contact-us';
import InformativePage from './components/pages/informative';
import PermissionsPage from './components/pages/permissions';
import DrawWinnersPage from './components/pages/drawwinners';
import ProbList from './components/pages/probabilitites/List';
import ProbShow from './components/pages/probabilitites/Show';
import ProbForm from './components/pages/probabilitites/Form';
import TransactionsPage from './components/pages/transactions';
import NotAuthorized from './components/layouts/NotAuthorized';
import PrivacyPolicyPage from './components/pages/privacy-policy';
import ClaimRequestsPage from './components/pages/claim-requests';
import ScratchWinnersPage from './components/pages/scratchwinners';


const THEME = createTheme({
  typography: {
    fontFamily: `Open Sans,sans-serif`,
  },
});

const App = ({ props }) => {
  return (
    <ThemeProvider theme={THEME}>
      <Box sx={{ display: 'flex' }} color='black'>
        <Alerts />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/cms/login' element={<Login />} />
          <Route
            path='/cms'
            element={
              <AuthRoute redirectTo='login'>
                <Main props={props} />
              </AuthRoute>
            }
          >
            {/* User profile page */}
            <Route path='profile' element={<Profile />} exact />

            {/* Admins pages */}
            <Route path='admins' element={<AdminsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            {/* Permissions pages */}
            <Route path='permissions' element={<PermissionsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            {/* Contact us pages */}
            <Route path='contact us' element={<ContactUsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
            </Route>

            {/* Find us pages */}
            <Route path='find us' element={<FindUsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            {/* SMTP pages */}
            <Route path='smtp' element={<SMTPPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            {/* About us pages */}
            <Route path='about us' element={<AboutUsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            {/* Privacy policy pages */}
            <Route path='privacy policy' element={<PrivacyPolicyPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            {/* Support pages */}
            <Route path='support' element={<SupportPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            {/* Links pages */}
            <Route path='links' element={<LinksPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            {/* terms pages */}
            <Route path='terms' element={<TermsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            {/* FAQs pages */}
            <Route path='faq' element={<FAQPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            {/* Roles pages */}
            <Route path='roles' element={<RolesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<RolesForm type='create' />} exact />
              <Route
                path=':id/edit'
                element={<RolesForm type='edit' />}
                exact
              />
            </Route>

            {/* Usres pages */}
            <Route path='users' element={<UsersPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            {/* Draws pages */}
            <Route path='draws' element={<DrawsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
              <Route path=":id/lots" element={<LotsList />} exact />
              <Route path=":id/lots/:lot_id" element={<LotsShow />} exact />
              <Route path=":id/lots/add" element={<LotsForm type="create" />} exact />
              <Route path=":id/lots/:lot_id/edit" element={<LotsForm type="edit" />} exact />
              <Route path=":id/lots/:lot_id/probabilities" element={<ProbList />} exact />
              <Route path=":id/lots/:lot_id/probabilities/:prob_id" element={<ProbShow />} exact />
              <Route path=":id/lots/:lot_id/probabilities/add" element={<ProbForm type="create" />} exact />
              <Route path=":id/lots/:lot_id/probabilities/:prob_id/edit" element={<ProbForm type="edit" />} exact />
            </Route>

            {/* Lots pages */}
            {/* <Route path='lots' element={<LotsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route> */}

            {/* Probabilities pages */}
            {/* <Route path='probabilities' element={<ProbabilitiesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route> */}

            {/* Scratch winners pages */}
            <Route path='scratchwinners' element={<ScratchWinnersPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            {/* Draw winners pages */}
            <Route path='drawwinners' element={<DrawWinnersPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            {/* Informative pages */}
            <Route path='informative' element={<InformativePage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            {/* ClaimRequests pages */}
            <Route path='claim requests' element={<ClaimRequestsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            {/* Section pages */}
            <Route path='section' element={<SectionPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path="add" element={<Form type="create" />} exact />
              <Route path="order" element={<Order />} exact />
              <Route path=":id/edit" element={<Form type="edit" />} exact />
            </Route>

            {/* Transactions pages */}
            <Route path='transactions' element={<TransactionsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
            </Route>
            
            {/* Currency pages */}
            <Route path='currency' element={<CurrencyPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
            </Route>

          </Route>
          <Route path='unauthorized' element={<NotAuthorized />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default App;
