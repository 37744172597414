const list = {
    header: [
        {
            id: 'id',
            label: 'User id',
            align: 'center',
            orderBy: 'id'
        },
        {
            id: 'first_name',
            label: 'First name',
            align: 'center',
        },
        {
            id: 'last_name',
            label: 'Last name',
            align: 'center',
        },
        {
            id: 'country_code',
            label: 'Country code',
            align: 'center',
        },
        {
            id: 'mobile_number',
            label: 'Mobile',
            align: 'center',
        },
        {
            id: 'email',
            label: 'Email',
            align: 'center',
        },
        {
            id: 'verified',
            label: 'Verified',
            align: 'center',
            switch: true,
        },
        {
            id: 'block',
            label: 'Blocked',
            align: 'center',
            switch: true
        },
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'remove', 'update'],
};

const show = {
    fields: [
        {
            type: "text",
            name: "first_name", 
            label: "First name"
        },
        {
            type: "text",
            name: "last_name", 
            label: "Last name"
        },
        {
            type: "text",
            name: "country_code", 
            label: "Country code"
        },
        {
            type: "text",
            name: "mobile_number", 
            label: "Mobile"
        },
        {
            type: "text",
            name: "email", 
            label: "Email"
        },
    ],
     permission: 'show'
};

const create = {
    fields: [
        {
            type: "text",
            name: "country_code", 
            label: "Country code",
            required: true
        },
        {
            type: "text",
            name: "mobile_number", 
            label: "Mobile number",
            required: true
        },
        {
            type: "text",
            name: "first_name", 
            label: "First name",
            required: true
        },
        {
            type: "text",
            name: "last_name", 
            label: "Last name",
            required: true
        },
        {
            type: "email",
            name: "email", 
            label: "Email",
            required: true
        },
        {
            type: 'password',
            name: 'password',
            label: 'Password',
        },
        {
            type: 'password',
            name: 'confirm_password',
            label: 'Confirm Password',
        }
    ]
};

const edit = {
    fields: [
        {
            type: "text",
            name: "first_name", 
            label: "First name",
            required: true
        },
        {
            type: "text",
            name: "last_name", 
            label: "Last name",
            required: true
        },
        {
            type: 'password',
            name: 'password',
            label: 'Password',
        },
        {
            type: 'password',
            name: 'confirm_password',
            label: 'Confirm Password',
        },
        {
            type: "number",
            name: "wallet", 
            label: "Wallet",
            required: true
        },
    ]
};

const search = {
    fields: [
        {
            type: "text",
            name: "id",
        },
        {
            type: "text",
            name: "first_name",
        },
        {
            type: "text",
            name: "last_name",
        },
        {
            type: "text",
            name: "email",
        },
        {
            type: "text",
            name: "mobile_number",
        },
    ]
}

const data = { list, show, create, edit, search };

export default data;