import React from 'react'
import { Outlet } from 'react-router-dom'
import { list, get, create, edit, remove, bulk_remove, end, check } from '../../../features/draws/actions';

const index = () => {

    const page = "Draws";

    return (
        <div style={{ height: '100%', paddingTop: "4rem" }}>
            <Outlet context={[{ page, list, create, get, edit, remove, bulk_remove, end, check }]} />
        </div>
    )
}

export default index;