const list = {
    header: [
        {
            id: 'title.en',
            label: 'Title (EN)',
            align: 'left'
        },
        {
            id: 'title.ar',
            label: 'Title (AR)',
            align: 'left'
        },
        {
            id: 'publish',
            label: 'Publish',
            align: 'left',
            switch: true,
            disabled: true
        }
    ],
    permission: 'list',
    hide: {
        create: true
    },
    actions: ['show', 'update']
};

const show = {
    fields: [
        {
            type: 'mixed',
            name: 'title',
            label: 'Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                },
            ],
        },
        {
            type: 'mixed',
            name: 'text',
            label: 'Text',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    rich: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    rich: true
                },
            ],
        },
    ],
    permission: 'show'
};

const edit = {
    fields: [
        {
            type: 'mixed',
            name: 'title',
            label: 'Title',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                },
            ],
        },
        {
            type: 'mixed',
            name: 'text',
            label: 'Text',
            required: true,
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English',
                    required: true,
                    rich: true
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic',
                    required: true,
                    rich: true
                },
            ],
        },
    ],
    permission: 'edit'
};

const search = {
    fields: [
        {
            type: 'text',
            name: 'title.en'
        },
        {
            type: 'text',
            name: 'title.ar'
        },
        {
            type: 'text',
            name: 'text.en'
        },
        {
            type: 'text',
            name: 'text.ar'
        },
        
    ]
};

const data = { list, show, edit, search };

export default data;