import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '../../layouts/Breadcrumbs'
import DataTable from '../../layouts/DataTable'
import PermissionsManager from '../../layouts/PermissionsManager'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { Add as AddIcon, Sort as SortIcon, DeleteSweep as DeleteSweepIcon } from '@mui/icons-material';
import { authorized } from '../../../utils/utils';
import Filter from '../default/Filter';
import Search from '../default/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { list, get, create, edit, remove, bulk_remove } from '../../../features/probabilities/actions';


const List = ({ permission }) => {
    const { id, lot_id } = useParams();
    const page = 'Probabilities';
    const dispatch = useDispatch();
    const { items, itemsLoading, count, page_number, limit } = useSelector((state) => state[page.toString().toLowerCase()]);
    const user = useSelector((state) => state.auth.user);
    const columns = useSelector((state) => state[page.toString().toLowerCase()].properties.list.header)
    const actions = useSelector((state) => state[page.toString().toLowerCase()].properties.list.actions)
    const hide = useSelector((state) => state[page.toString().toLowerCase()].properties.list.hide)
    const filter = false
    // const filter = useSelector((state) => state[page.toString().toLowerCase()].properties.filter)
    const search = useSelector((state) => state[page.toString().toLowerCase()].properties.search)
    const [orderData, setOrderData] = useState([]);
    const [bulk, setBulk] = useState([]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        dispatch(bulk_remove({ data: { ids: bulk } }))
    };

    useEffect(() => {
        // if (itemsLoading) {
        dispatch(list({ page: 0, limit: 10, orderBy: null, orderDirection: null, filter: { lot_id }, search: null }))
        // }
    }, [])

    const bulkToggle = (value, group) => {
        if (group) {
            setBulk(value)
        } else {
            if (bulk.includes(value))
                setBulk(bulk.filter(item => item !== value))
            else
                setBulk([...bulk, value])
        }

    }

    const onDelete = (id) => {
        dispatch(remove(id));
    }

    /* Filter Code */
    const [filterFormData, setFilterFormData] = useState(null);

    /* Search Code */
    const [searchFormData, setSearchFormData] = useState('');

    return user?.Permissions && <PermissionsManager action="list" name={page.toLowerCase()} permissions={user?.Permissions}>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ borderRadius: 0 }}
        >
            <DialogTitle id="alert-dialog-title" >
                Confirm
            </DialogTitle>
            <DialogContent >
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove all these items?
                </DialogContentText>
            </DialogContent>
            <DialogActions >
                <Button onClick={handleClose}>Keep</Button>
                <Button onClick={handleConfirm} autoFocus color="error">
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
        <Box height="100%" >
            {hide?.breadcrumbs !== true && <Breadcrumbs page={page.toString()} text={'List'} />}
            {/* Header */}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" mb={2}>
                {/* Title */}
                <Stack direction="row" spacing={1} alignItems="center" alignSelf={{ xs: "start", sm: "center" }}>
                    <IconButton
                        component={RouterLink}
                        to={`/cms/draws/${id}/lots`}
                        aria-label='arrow-back'
                        sx={{ border: '2px solid', borderRadius: '50%' }}
                        disableRipple
                        size='small'
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    {hide?.title !== true && <Typography variant="h5" mr={1}>
                        {page}
                    </Typography>}
                    {hide?.count !== true && <Chip label={count} size="small" />}
                </Stack>

                {/* Buttons */}
                <Stack direction="row" spacing={2} mt={{ xs: 2, sm: 0 }} alignSelf={{ xs: "end", sm: "center" }}>
                    {(hide?.create !== true && authorized(user.Permissions, page.toLowerCase(), 'form')) && <Button component={RouterLink} to="add" variant="contained" startIcon={<AddIcon />} sx={{ borderRadius: 0, textTransform: "none" }}>
                        Create new
                    </Button>}

                    {(actions?.includes('bulk remove') && authorized(user.Permissions, page.toLowerCase(), 'remove')) && <Button color="error" onClick={() => handleClickOpen()} variant="contained" startIcon={<DeleteSweepIcon />} sx={{ borderRadius: 0, textTransform: "none" }}>
                        Bulk Remove
                    </Button>}

                    {filter && <Filter
                        filter={filter}
                        dispatch={dispatch}
                        list={list}
                        formData={filterFormData}
                        setFormData={setFilterFormData}
                        search={searchFormData === '' ? undefined : { keys: search?.fields.map((item) => item.name).join(','), value: searchFormData }}
                        order={orderData}
                        setOrder={setOrderData}
                    />}
                </Stack>
            </Stack>
            <Search
                filter={filterFormData}
                search={search}
                dispatch={dispatch}
                list={list}
                formData={searchFormData}
                setFormData={setSearchFormData}
                order={orderData}
                setOrder={setOrderData} />

            {/* Body */}
            <DataTable
                columns={columns}
                rows={items}
                onDelete={onDelete}
                dispatch={dispatch}
                edit={edit}
                count={count}
                list={list}
                permissions={user?.Permissions}
                authorized={authorized}
                name={page.toLowerCase()}
                actions={actions}
                bulk={bulk}
                bulkToggle={bulkToggle}
                filter={filterFormData}
                search={searchFormData === '' ? undefined : { keys: search?.fields.map((item) => item.name).join(','), value: searchFormData }}
                order={orderData}
                page={page_number}
                rowsPerPage={limit}
                setOrder={setOrderData}
                hidePagination={hide?.pagination === true}
                query={''}
            />
        </Box>
    </PermissionsManager>
}

export default List