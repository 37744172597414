const list = {
    header: [
        {
            id: 'amount',
            label: '# Winning tickets',
            align: 'left',
            order: false
        },
        {
            id: 'value',
            label: 'Prize Value (AED)',
            align: 'left',
            order: false
        },
        {
            id: 'Lot.number',
            label: 'Lot',
            align: 'left',
            order: false
        },
        {
            id: 'Lot.remaining_to_be_won',
            label: 'Remaining to be won',
            align: 'left',
            order: false
        },
        {
            id: 'Lot.winning_tickets',
            label: 'Winning Tickets',
            align: 'left',
            order: false
        },
        {
            id: 'Lot.Draw.img_title.en',
            label: 'Draw Title',
            align: 'left',
            order: false
        }
    ],
    permission: 'list',
    actions: ['bulk remove', 'show', 'remove', 'update']
};

const show = {
    fields: [
        {
            type: 'number',
            name: 'value',
            label: 'Prize Value (AED)'
        },
        {
            type: 'number',
            name: 'amount',
            label: '#Winning tickets'
        },
        {
            type: 'number',
            name: 'Lot.number',
            label: 'Lot.number'
        },
    ],
    permission: 'show'
};

const create = {
    fields: [
        {
            type: 'number',
            name: 'value',
            label: 'Prize Value (AED)'
        }
    ],
    permission: 'create'
};

const edit = {
    fields: [
        {
            type: 'number',
            name: 'value',
            label: 'Prize Value (AED)'
        },

    ],
    permission: 'edit'
};

const search = {
    fields: [
    ]
};

const data = { list, show, create, edit, search };

export default data;