import React from 'react'
import ActionLink from './ActionLink'

const Action = ({ action, id }) => {
    switch (action.type) {
        case 'link':
            return (<ActionLink to={action.to} icon={action.icon} id={id} />)
        default:
            return <></>
    }
}

export default Action