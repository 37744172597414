import { IconButton } from '@mui/material'
import React from 'react'
import Icon from '../layouts/Icon';

const ActionButton = ({ click, icon, sx, variant, disableRipple }) => {
    return (
        <IconButton
            onClick={click}
            sx={sx ?? {
                padding: 0,
                '&:hover': { color: '#1976d2' },
                transition: 'all 0.2s',
            }}
            variant={variant ?? 'contained'}
            disableRipple={disableRipple ?? true}
        >
            <Icon type={icon} />
        </IconButton>
    )
}

export default ActionButton