import { createAsyncThunk } from "@reduxjs/toolkit";
// Import Axios Admin API (this is where the baseURL is and shared reuqest params are set)
import api from "../../utils/api";
import { setAlert } from "../alerts/actions";

export const list = createAsyncThunk("drawwinners/list", async (params = { page: 0, limit: 10, orderBy: null, orderDirection: null, filter: null, search: null }) => {
    try {
        const page = params.page ? params.page : 0
        const limit = params.limit ? params.limit : 10
        const res = await api.get(`/admin/drawwinners`, { params });
        console.log(res);
        console.log(page, limit)
        return { ...res.data, page, limit };
    } catch (error) {
        console.log(error);
    }
});

export const edit = createAsyncThunk("drawwinners/edit", async ({ id, data, navigate }, { dispatch }) => {
    try {
        await api.put(`/admin/drawwinners/${id}`, data);
        dispatch(setAlert({ message: 'Game updated successfully', alertType: 'success' }))
        dispatch(list());
        if (navigate)
            navigate(`/cms/drawwinners`);
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});

export const get = createAsyncThunk("drawwinners/get", async (id, { dispatch }) => {
    try {
        const res = await api.get(`/admin/drawwinners/${id}`);
        return res.data;
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert({ message: error.message, alertType: 'error' })));
        }
    }
});